import { PiHandshakeDuotone } from 'react-icons/pi';
import { LuSettings2 } from 'react-icons/lu';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { RiChatSmile2Line } from 'react-icons/ri';

const OurQuality = () => {
  return (
    <div className="bg-red-600 rounded-t-3xl">
      <div
      className='rounded-t-3xl'
        style={{
          backgroundImage: "url('/assets/svgs/bg-shinny-1.svg')",
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto rounded-3xl dark">
          <h2 className="text-2xl text-gray-800 text-center font-bold sm:text-3xl dark:text-white">
            Mengapa Memilih Kami
          </h2>
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-12 mt-20">
            <div>
              <PiHandshakeDuotone
                size={24}
                className="flex-shrink-0 size-9 text-gray-800 dark:text-white"
              />
              <div className="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
                <div className="bg-gray-white w-9 h-0.5"></div>
              </div>
              <div className="mt-5">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                  Komitmen Terhadap Kualitas
                </h3>
                <p className="mt-5 text-gray-600 dark:text-white">
                  Kami menjamin barang anda sampai dengan aman dan tepat waktu.
                </p>
              </div>
            </div>

            <div>
              <LuSettings2
                size={24}
                className="flex-shrink-0 size-9 text-gray-800 dark:text-white"
              />
              <div className="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
                <div className="bg-white w-9 h-0.5"></div>
              </div>
              <div className="mt-5">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                  Layanan Fleksibel
                </h3>
                <p className="mt-5 text-gray-600 dark:text-white">
                  Kami menyediakan berbagai pilihan yang dapat disesuaikan
                  sesuai permintaan Anda.
                </p>
              </div>
            </div>

            <div>
              <HiOutlineUserGroup
                size={24}
                className="flex-shrink-0 size-9 text-gray-800 dark:text-white"
              />
              <div className="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
                <div className="bg-white w-9 h-0.5"></div>
              </div>
              <div className="mt-5">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                  Tim Profesional
                </h3>
                <p className="mt-5 text-gray-600 dark:text-white">
                  Kami selalu siap memberikan solusi terbaik untuk setiap
                  kebutuhan pengiriman Anda.
                </p>
              </div>
            </div>

            <div>
              <RiChatSmile2Line
                size={24}
                className="flex-shrink-0 size-9 text-gray-800 dark:text-white"
              />
              <div className="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
                <div className="bg-white w-9 h-0.5"></div>
              </div>
              <div className="mt-5">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                  Ramah dan Responsif
                </h3>
                <p className="mt-5 text-gray-600 dark:text-white">
                  Kami berkomitmen untuk memberikan pengalaman yang memuaskan
                  kepada pelanggan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurQuality;
