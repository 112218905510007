import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import 'preline/preline';
import { IStaticMethods } from 'preline/preline';
import HomePage from './pages/Home.page';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

function App() {
  const location = useLocation();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  return (
    <div className="hs-default-mode-active">
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
