const Clients = () => {
  return (
    <div className="" id="clients">
      <div className="max-w-[85rem] px-4 py-14 sm:px-6 lg:px-8 lg:py-20 mx-auto">
        <div className="sm:w-1/2 xl:w-1/3 mx-auto text-center mb-6 md:mb-12">
          <h2 className="text-xl font-semibold md:text-2xl md:leading-tight text-gray-800 dark:text-gray-200">
            Client kami
          </h2>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-6 place-content-center">
          <div className="p-4 md:p-7 bg-gray-100 rounded-lg dark:bg-slate-800 flex justify-center">
            <img
              src="/assets/images/pt_ciomas.webp"
              alt="pt_ciomas"
              className="w-[100px] object-contain"
            />
          </div>

          <div className="p-4 md:p-7 bg-gray-100 rounded-lg dark:bg-slate-800 flex justify-center">
            <img
              src="/assets/images/surya_dermanto.png"
              alt="surya_dermanto"
              className="w-[200px] object-contain"
            />
          </div>

          <div className="p-4 md:p-7 bg-gray-100 rounded-lg dark:bg-slate-800 flex justify-center">
            <img
              src="/assets/images/pgm.jpeg"
              alt="pgm"
              className="w-[200px] object-contain"
            />
          </div>

          <div className="p-4 md:p-7 bg-gray-100 rounded-lg dark:bg-slate-800 flex justify-center">
            <img
              src="/assets/images/pgm.jpeg"
              alt="pgm"
              className="w-[200px] object-contain"
            />
          </div>

          <div className="p-4 md:p-7 bg-gray-100 rounded-lg dark:bg-slate-800 flex justify-center">
            <img
              src="/assets/images/pgm.jpeg"
              alt="pgm"
              className="w-[200px] object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
