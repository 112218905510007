const ContactUs = () => {
  return (
    <div className="" id="contact-us">
      <div
        style={{
          backgroundImage: "url('/assets/svgs/bg-wave-2.svg')",
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="max-w-2xl lg:max-w-5xl mx-auto">
            <div className="text-center">
              <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
                Hubungi Kami
              </h1>
              <p className="mt-3 text-gray-600 dark:text-gray-400">
                Dengan senang hati kami akan membantu
              </p>
            </div>

            <div className="mt-12 grid lg:grid-cols-2 gap-6 lg:gap-16">
              <div className="flex flex-col h-full border rounded-xl bg-white p-4 sm:p-6 lg:p-8 dark:border-gray-700">
                <h2 className="mb-8 text-xl font-semibold text-gray-800 dark:text-gray-200">
                  Kirim pesan:
                </h2>

                <form>
                  <div className="grid gap-4">
                    <div>
                      <label className="sr-only">Details</label>
                      <textarea
                        id="hs-about-contacts-1"
                        name="hs-about-contacts-1"
                        rows={4}
                        className="py-3 px-4 block w-full border-2 border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      >
                        Saya ingin membuat pengiriman ....
                      </textarea>
                    </div>
                  </div>

                  <div className="mt-4 grid">
                    <button
                      type="submit"
                      className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    >
                      Kirim Pesan
                    </button>
                  </div>

                  <div className="mt-3 text-center">
                    <p className="text-sm text-gray-500 dark:text-gray-200">
                      Kami akan segera membalas di jam kerja
                    </p>
                  </div>
                </form>
              </div>

              <div className="flex flex-col gap-5">
                <h2 className="mb-5 text-xl font-semibold text-gray-800 dark:text-gray-200">
                  Lokasi
                </h2>
                <p className="text-gray-700 font-bold dark:text-gray-200">
                  PT. Baja Sarana Perkasa Logistics
                </p>
                <p className="text-gray-500 dark:text-gray-200">
                  Pasar Wisata, Jl. Raya By pass Juanda Jl. Raya Pabean No.D-10,
                  Dabean, Pabean, Kec. Sedati, Kabupaten Sidoarjo, Jawa Timur
                  61253
                </p>

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3956.8282158478773!2d112.7616690108517!3d-7.373142372535155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7e4dd6483683b%3A0xb114f4ad07cbeb53!2sPT.%20Baja%20Sarana%20Perkasa%20Logistik%20(BSP%20Logistics)!5e0!3m2!1sid!2sid!4v1711638999924!5m2!1sid!2sid"
                  title="mapBSP"
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  className="w-full h-96"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
