import BlogSection from '../components/BlogSection';
import Clients from '../components/Clients/Clients.component';
import ContactUs from '../components/ContactUs';
import Hero from '../components/Hero';
import OurQuality from '../components/OurQuality';
import Services from '../components/Services';

const HomePage = () => {
  return (
    <div className='flex flex-col gap-10'>
      <Hero />
      <Services />
      <OurQuality />
      <Clients />
      <ContactUs />
      <BlogSection />
    </div>
  );
};

export default HomePage;
