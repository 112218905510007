import { FaCheckCircle, FaClock, FaDotCircle } from 'react-icons/fa';

const TimelineItem = ({
  status,
  name
}: {
  status: 'ongoing' | 'done' | 'pending';
  name: string;
}) => {
  const renderIconLeft = () => {
    switch (status) {
      case 'done':
        return <FaCheckCircle className="text-green-500" />;
      case 'ongoing':
        return <FaClock className="text-blue-500" />;

      case 'pending':
        return <FaDotCircle className="text-gray-300" />;
      default:
        return <FaDotCircle className="text-gray-300" />;
    }
  };

  return (
    <div className="flex gap-x-3 my-2">
      <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
        <div className="relative z-10 size-7 flex justify-center items-center">
          {renderIconLeft()}
        </div>
      </div>

      <div className="grow pt-0.5 pb-8">
        <h3
          className={`flex gap-x-1.5 font-semibold ${
            status === 'pending' ? 'text-gray-300' : 'text-gray-800'
          } dark:text-white`}
        >
          {name}
        </h3>
      </div>
    </div>
  );
};

const Timeline = () => {
  return (
    <div>
      <div className="ps-2 my-2 first:mt-0">
        <h3 className="text-xs font-medium uppercase text-gray-500 dark:text-gray-400 mb-3">
          1 Agustus, 2023
        </h3>
        <TimelineItem status="done" name="Paket diproses" />

        <h3 className="text-xs font-medium uppercase text-gray-500 dark:text-gray-400 mb-3">
          2 Agustus, 2023
        </h3>
        <TimelineItem status="ongoing" name="Paket dikirim" />

        <TimelineItem status="pending" name="Paket telah diterima" />
      </div>
    </div>
  );
};

export default Timeline;
