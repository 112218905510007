import { FaInstagram, FaSms, FaWhatsapp } from 'react-icons/fa';
import { HiMail } from 'react-icons/hi';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="bg-blue-900 dark">
      <footer className="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto mt-56">
        <div className="text-center">
          <div className="justify-center flex w-full">
            <Link to={'/'} className="">
              <img src="/assets/images/logo.jpeg" alt="logo" className="h-10" />
            </Link>
          </div>

          <div className="mt-3">
            <p className="text-white font-bold">
              PT. Baja Sarana Perkasa Logistics
            </p>
            <p className="text-gray-200">
              Pasar Wisata, Jl. Raya By pass Juanda Jl. Raya Pabean No.D-10,
              Dabean, Pabean, Kec. Sedati, Kabupaten Sidoarjo, Jawa Timur 61253
            </p>
            <p className="text-gray-200 mt-10">
              © PT. Baja Sarana Perkasa Logistics. 2024. All rights reserved.
            </p>
          </div>

          <div className="mt-3 space-x-2">
            <Link
              className="inline-flex justify-center items-center size-10 text-center text-white hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-white dark:hover:text-gray-200 dark:hover:bg-gray-800"
              to="#"
            >
              <FaWhatsapp />
            </Link>
            <Link
              className="inline-flex justify-center items-center size-10 text-center text-white hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-white dark:hover:text-gray-200 dark:hover:bg-gray-800"
              to="#"
            >
              <FaSms />
            </Link>
            <Link
              className="inline-flex justify-center items-center size-10 text-center text-white hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-white dark:hover:text-gray-200 dark:hover:bg-gray-800"
              to="#"
            >
              <HiMail />
            </Link>
            <Link
              className="inline-flex justify-center items-center size-10 text-center text-white hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-white dark:hover:text-gray-200 dark:hover:bg-gray-800"
              to="#"
            >
              <FaInstagram />
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
