import { PiTruckFill, PiAirplaneDuotone } from 'react-icons/pi';
import { RiShipLine } from 'react-icons/ri';

const Services = () => {
  return (
    <div className="" id="services">
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="relative p-6 md:p-16">
          <div className="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
            <div className="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 lg:order-2">
              <h2 className="text-2xl text-gray-800 font-bold sm:text-3xl dark:text-gray-200">
                Layanan pengiriman kami
              </h2>
              <nav
                className="grid gap-4 mt-5 md:mt-10"
                aria-label="Tabs"
                role="tablist"
              >
                <button
                  type="button"
                  className="hs-tab-active:bg-blue-600 hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 active"
                  id="tabs-with-card-item-1"
                  data-hs-tab="#tabs-with-card-1"
                  aria-controls="tabs-with-card-1"
                  role="tab"
                >
                  <span className="flex">
                    <PiTruckFill className="flex-shrink-0 mt-2 size-6 md:size-7 hs-tab-active:text-white text-gray-800 dark:hs-tab-active:text-white dark:text-gray-200" />
                    <span className="grow ms-6">
                      <span className="block text-lg font-semibold hs-tab-active:text-white text-gray-800 dark:hs-tab-active:text-white dark:text-gray-200">
                        Pengiriman Darat
                      </span>
                      <span className="block mt-5 text-gray-800 dark:hs-tab-active:text-white dark:text-white hs-tab-active:text-white">
                        Pengangkutan barang darat adalah solusi yang cepat dan
                        efisien untuk pengiriman dalam jarak dekat maupun jarak
                        jauh.
                      </span>
                    </span>
                  </span>
                </button>

                <button
                  type="button"
                  className="hs-tab-active:bg-blue-600 hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  id="tabs-with-card-item-2"
                  data-hs-tab="#tabs-with-card-2"
                  aria-controls="tabs-with-card-2"
                  role="tab"
                >
                  <span className="flex">
                    <RiShipLine className="flex-shrink-0 mt-2 size-6 md:size-7 hs-tab-active:text-white text-gray-800 dark:hs-tab-active:text-white dark:text-gray-200" />
                    <span className="grow ms-6">
                      <span className="block text-lg font-semibold hs-tab-active:text-white text-gray-800 dark:hs-tab-active:text-white dark:text-gray-200">
                        Pengiriman Laut
                      </span>
                      <span className="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200 hs-tab-active:text-white">
                        Pengangkutan barang laut merupakan pilihan ideal untuk
                        pengiriman dalam jumlah besar dan jarak jauh.
                      </span>
                    </span>
                  </span>
                </button>

                <button
                  type="button"
                  className="hs-tab-active:bg-blue-600 hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  id="tabs-with-card-item-3"
                  data-hs-tab="#tabs-with-card-3"
                  aria-controls="tabs-with-card-3"
                  role="tab"
                >
                  <span className="flex">
                    <PiAirplaneDuotone className="flex-shrink-0 mt-2 size-6 md:size-7 hs-tab-active:text-white text-gray-800 dark:hs-tab-active:text-white dark:text-gray-200" />
                    <span className="grow ms-6">
                      <span className="block text-lg font-semibold hs-tab-active:text-white text-gray-800 dark:hs-tab-active:text-white dark:text-gray-200">
                        Pengiriman Udara
                      </span>
                      <span className="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200 hs-tab-active:text-white">
                        Untuk pengiriman yang membutuhkan waktu cepat, layanan
                        pengangkutan barang udara kami merupakan solusi terbaik.
                      </span>
                    </span>
                  </span>
                </button>
              </nav>
            </div>

            <div className="lg:col-span-6">
              <div className="relative">
                <div>
                  <div
                    id="tabs-with-card-1"
                    role="tabpanel"
                    aria-labelledby="tabs-with-card-item-1"
                  >
                    <img
                      className="shadow-xl object-center object-cover h-[600px] w-[600px] rounded-xl dark:shadow-gray-900/[.2]"
                      src="/assets/images/bsp-6.jpeg"
                      alt="pengiriman_darat"
                    />
                  </div>

                  <div
                    id="tabs-with-card-2"
                    className="hidden"
                    role="tabpanel"
                    aria-labelledby="tabs-with-card-item-2"
                  >
                    <img
                      className="shadow-xl object-cover h-[600px] w-[600px] rounded-xl dark:shadow-gray-900/[.2]"
                      src="https://images.unsplash.com/photo-1635690926948-06e5d7af93bb?q=80&w=2624&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="pengiriman_laut"
                    />
                  </div>

                  <div
                    id="tabs-with-card-3"
                    className="hidden"
                    role="tabpanel"
                    aria-labelledby="tabs-with-card-item-3"
                  >
                    <img
                      className="shadow-xl object-cover h-[600px] w-[600px] rounded-xl dark:shadow-gray-900/[.2]"
                      src="https://images.unsplash.com/photo-1482351437900-d57cf1b9cd37?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="pengiriman_udara"
                    />
                  </div>
                </div>
              </div>

              {/* <div className="absolute -bottom-10 -left-10 -z-20">
                <div className="bg-red-600 h-[500px] w-[500px] rounded-xl"></div>
              </div> */}
            </div>
          </div>

          <div className="absolute inset-0 grid grid-cols-12 size-full">
            <div className="col-span-full lg:col-span-7 lg:col-start-6 bg-gray-100 w-full h-5/6 rounded-xl sm:h-3/4 lg:h-full dark:bg-white/[.075]"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
